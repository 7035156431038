import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Grid, Tab } from '@mui/material';
import { IProfile } from '../../interfaces/IViewProfile.interface';
import { Header } from './header/Header';
import { JFContainer, SocialMedia } from '../../../common/components';
import { ProfileDetails } from '../newPublicProfile/about/ProfileDetails';
import { ProfileFunding } from './profileFunding/ProfileFunding';
import { useCancellableEffect } from '../../hooks/useCancellableEffect';
import { PublicAPIService } from '../../services/api-service/public-api.service';
import FundedOrganizationsModal from '../newPublicProfile/funded/fundedOrganizationsModal';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { BluredInfo } from './bluredInfo/BluredInfo';
import { NotFoundPage } from '../../util/NotFoundPage';
import FundedTabContent from './fundedTabContent/FundedTabContent';

interface CustomPublicProfileParams {
  profileSlug: string;
}
type IEntity = IProfile & {
  based_in?: { name: string };
  totalFundedTab: number;
}

export const CustomPublicProfile = () => {
  const [entity, setEntity] = useState<IEntity>({} as IEntity);
  const [isPublicProfile, setIsPublicProfile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { profileSlug } = useParams<CustomPublicProfileParams>()
  const [isOrganizationsModalOpen, setIsOrganizationsModalOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab]: [string, Dispatch<SetStateAction<string>>] = useState("about");

  const isEntityEmpty = Object.keys(entity).length === 0;
  const funderId = entity.id;
  const socialMedia = {
    facebook: entity.facebook,
    instagram: entity.instagram,
    twitter: entity.twitter,
    linkedIn: entity.linkedIn,
    website: entity.website,
  };

  const tabHandler = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  }

  useCancellableEffect((signal: AbortSignal) => {
    PublicAPIService.getFunderBySlug(profileSlug, { signal })
    .then((data) => {
      setEntity(data);
      setIsPublicProfile(true);
      setIsLoading(false);
    })
    .catch(() => {
      setIsPublicProfile(false);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [profileSlug]);

  useEffect(() => {
    if (entity.name)
      document.title = `JustFund | ${entity.name}`;
  }, [entity]);

  if (isLoading) {
    return <CircularProgress />;
  }
  
  return (
    <JFContainer>
      { !isPublicProfile ? (
        <NotFoundPage />
      ) : (
      <>
        <Header entity={entity} />
        <Box
          display='flex'
          justifyContent='end'
          marginBottom={-5}
          sx={{
            "@media (max-width: 600px)": {
              justifyContent: 'center',
              marginBottom: '16px'
            }
          }}
        >
          <SocialMedia socialMedia={socialMedia} />
        </Box>
        <TabContext value={selectedTab}>
          <TabList
            onChange={tabHandler}
            sx={{ 
              '.MuiTab-root': {
                fontSize: '16px',
                textTransform: 'none'
              },
              '.Mui-selected': {
                color: '#4288C5'
              },
              '.MuiTabs-indicator': {
                backgroundColor: '#4288C5',
                height: 3
              },
            }}
          >
            <Tab value="about" label="About" />
            <Tab value="funded" label="Funded" />
            <Tab value="activity" label="Activity" />
          </TabList>
          <TabPanel sx={{ paddingX: 0 }} value="about">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid item>
                  <Grid item marginBottom='24px'>
                    <ProfileDetails entity={entity} isPublicProfile />
                  </Grid>
                  <Grid item>
                    <BluredInfo selectedTab={selectedTab} name={entity.name} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} height={'fit-content'}>
                {!isEntityEmpty ? (
                  <ProfileFunding 
                    entity={entity}
                    profileSlug={profileSlug}
                    setIsOrganizationsModalOpen={setIsOrganizationsModalOpen}
                  />
                ) : null}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel sx={{ paddingX: 0 }} value="funded">
            { selectedTab === 'funded' && (
            <>
              <FundedTabContent
                profileSlug={profileSlug}
                setIsOrganizationsModalOpen={setIsOrganizationsModalOpen}
                totalFundedTab={entity.totalFundedTab}
              />
              <BluredInfo selectedTab={selectedTab} name={entity.name} />
            </>
            )}
          </TabPanel>
          <TabPanel sx={{ paddingX: 0 }} value="activity">
            <BluredInfo selectedTab={selectedTab} name={entity.name} />
          </TabPanel>
        </TabContext>
        {isOrganizationsModalOpen && (
          <FundedOrganizationsModal
            isOpen={isOrganizationsModalOpen}
            handleClose={() => setIsOrganizationsModalOpen(false)}
            funderId={funderId}
            isAdmin={false}
            profileSlug={profileSlug}
            funderName={entity.name}
          />
        )}
        </>
      )}
    </JFContainer>
  );
};